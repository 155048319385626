export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavDropdown',
        name: 'Trading',
        route: '/trading',
        icon: 'cilBarChart',
        items: [ 
          {
            name: 'CryptoDashboard',
            to: '/crew/trading/CryptoDashboard',
            icon: 'cil-speedometer',
          },
          {
          name: 'Multipropiedad',
          to: '/crew/trading/Multipropiedad',
          icon: 'cil-group',
        },
        {
        name: 'Assets',
        to: '/crew/trading/Assets',
        icon: 'cil-cash',
        },
      {
        name: 'Exchanges',
        to: '/crew/trading/Exchanges',
        icon: 'cil-basket',
      }
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Crypto gateway',
        route: '/crypto-gateway',
        icon: 'cil-loop-circular',
        items: [ 
            {
              _name: 'CSidebarNavItem',
              name: 'Crypto',
              to: '/crew/crypto-gateway/crypto',
              icon: 'cib-bitcoin',
            },
        ]
      },
      {
      _name: 'CSidebarNavDropdown',
      name: 'Labs',
      route: '/labs',
      icon: 'cil-education',
      items: [ 
        {
          _name: 'CSidebarNavItem',
          name: 'API_test',
          to: '/crew/labs/api_test',
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Dashboard',
          to: '/crew/labs/Dashboard',
          icon: 'cil-speedometer',
        },
        {
          _name: 'CSidebarNavItem',
          name: 'API_test2',
          to: '/crew/labs/API_test2',
          icon: 'cilGem',
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Uniswap',
          to: '/crew/labs/Uniswap',
          icon: 'cilGem',
        },
      ]
    }
    ]
  }
]